define('offer-form',[
    'jquery',
    'TweenMax',
    'form-validator'
], function($, TweenMax, FormValidator)
{
    'use strict';
       
    /**
     * Class name.
     * 
     * @type String
     */
    var sName = 'OfferForm';       
       
    /**
     * Price Calculator class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2016, SOPG
     * 
     * Created: 2015-08-12
     * Updated: 2015-05-24
     * 
     * @returns {undefined}
     */
    var OfferForm = function()
    {  
        'use strict';

        /**
         * Own instance.
         * 
         * @type BaseSlider
         */
        var self = this;

        /**
         * Module wide selectors.
         * 
         * @type Object
         */
        var oSel = {
            form : '#burialoffer-form, #contactForm',
            contactFormSubmit : '.js-contact-form-submit'
        };

        this._oFormValidator = null;

        /**
         * Module wide settings.
         * 
         * @type Object
         */
        var oSet = {
//            minCityFieldChars  : 3,
//            ajaxCityListSrcUrl : '/ajax/cityList',
//            paramSeparator     : '&&'
        };


        /**
         * Initializes the Offer Form.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * Updated: 2016-05-24
         */
        
        this.initialize = function()
        {
            log('Initializing ...');
            
            var $form = $(oSel.form);
            
            $form.keydown(function(oEvent)
            {
                if (13 === oEvent.keyCode 
                /* DON'T prevent default for textareas! */
                && ('TEXTAREA' !== oEvent.target.nodeName)) {
                  oEvent.preventDefault();
                  
                  return false;
                }
            });
            
            if (0 < $form.length) {
                self._initFormValidator();
                self._initFormButtons($form);
                self._initContactFormSubmit($form);
            }
            
            log('Initialized.');
        };
        
        this._showError = function($field){
            $field.addClass('error');
            var $errorMsg = $field.find($('.error-msg'));
            var $iHeight = $errorMsg.height();
            var $iOpacity = $errorMsg.css('opacity');
            TweenMax.set($errorMsg, { height:'auto', opacity: 1});
            TweenMax.from($errorMsg, 1, { height: $iHeight, opacity: $iOpacity });
        };
        
        this._hideError = function($field){
            $field.removeClass('error');
            var $errorMsg = $field.find($('.error-msg'));
            TweenMax.to($errorMsg, 1, { height: 0, opacity: 0 });
        };
        
        this._validateField = function($field, $iValide)
        {
            var $sValidation = $field.attr("data-validation");
            switch($sValidation){
                case 'checked':
                 {
                     
                    if( 0 ===  $field.find('input:checked').length ){
                          $iValide -= 1;
                          self._showError($field);
                     } else{
                         self._hideError($field);
                     }
                     break;
                 }
                 case 'email':{
                     if( self._oFormValidator._isEmail($field.find('input').val())){
                         self._hideError($field);
                     } else{
                         $iValide -= 1;
                         self._showError($field);
                     }
                     break;
                 }
                 case 'date':{
                     if(self._oFormValidator._isDate($field.find('input').val() )){
                         self._hideError($field);
                     } else{
                         $iValide -= 1;
                         self._showError($field);
                     }
                     break;
                 }
                 case 'empty':{
                     if(self._oFormValidator._IsNotEmpty($field.find('input, textarea').val() )){
                         self._hideError($field);
                     } else{
                         $iValide -= 1;
                         self._showError($field);
                     }
                     break;
                 }
                 default:{
                     console.log('other');
                     break;
                 }
            }
            return $iValide;
        };
        
        /**
         * Validate Form
         * module by click submit for valid form
         * 
         * @author Kai Schönhoff <ks@sopg.de>
         * 
         * Created: 2015-12-03
         * 
         * hide/show error Messages
         * @returns bool
         */
        this._validateForm = function($form)
        {
            var $iValide = 0;
            var $requiredFields = $form.find($('.required'));
            if(0 < $requiredFields.length){
                   $requiredFields.each(function(){
                   var $field = $(this);
                   $iValide = self._validateField($field, $iValide);
                });
            }
            var $errorFields = $form.find($('.required.error'));
            $errorFields.bind('change keyup paste autocompleteselect', function(){
                var $field = $(this);
//                console.log($field);
                self._validateField($field, $iValide);
            });
//            $errorFields.bind('input propertychange', function(){
//                var $field = $(this);
//                console.log($field);
//                self._validateField($field, $iValide);
//            });            
            console.log($iValide);
            if ( $iValide < 0 ){
                return false;
            }
            else{
                return true;
            }
        };
        
        
        
        /**
         * Initializes Form Buttons
         * module by instantiating it.
         * 
         * @author Kai Schönhoff <ks@sopg.de>
         * 
         * Created: 2015-12-03
         * 
         * @returns bool
         */
        this._initFormButtons = function($form)
        {
            
            var $submitInvalid = $form.find($('#submitInvalid'));
            var $submitValid = $form.find($('#submitValid'));
            var $textFormAnswerValid = $form.find($('.form-answer.valid'));
            var $textFormAnswerInvalid = $form.find($('.form-answer.invalid'));
            
            $submitInvalid.bind('click',function(oEvnt)
            {
                $form.attr('action', '/form/generatePdf/form');
                return true;
            });
            
            $submitValid.bind('click',function(oEvnt){
                var isValid = false;   
                
                isValid = self._validateForm($form);
                
                if (isValid) {
                    //$form.attr('action', '/form/generatePdf/confirmation');
                    $textFormAnswerValid.addClass('show');
                    $textFormAnswerInvalid.removeClass('show');
                    $textFormAnswerInvalid = $form.find($('.form-answer.invalid'));
                    oEvnt.preventDefault();
                    return false;
                } else {
                    //alert("not valid!");
                    $textFormAnswerInvalid.addClass('show');
                    $textFormAnswerValid.removeClass('show');
                    $('html, body').animate({
                        scrollTop: $('.error').offset().top -20 
                    }, 'slow');
                    oEvnt.preventDefault();
                    return false;
                }
            });  
        };


        /**
         * Instantiates and sets the price calculator.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * 
         * @returns {undefined}
         */
        this._initFormValidator = function()
        {
            self._oFormValidator = new FormValidator();
        };
        
        
        /**
         * Initializes the contact form submission.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-05-24
         * 
         * @param {jQuery} $form
         * @returns {undefined}
         */
        this._initContactFormSubmit = function($form)
        {
            var $submit = $form.find(oSel.contactFormSubmit);
            
            if (0 < $submit.length) {
                
                $submit.bind('click', function(oEvent) 
                {
                    oEvent.preventDefault();
                    
                    var bIsValid = self._validateForm($form);
                    
                    $.ajax(
                    {
                        url    : '/ajax/checkCaptcha',
                        method : 'post',
                        data   : {
                            captcha : $('#captcha').val()
                        },
                        complete : function(oResponse)
                        {
                            if (parseInt(oResponse.responseText)) {

                                $('#captcha').removeClass('invalid');
                                bIsValid = self._validateForm($form);

                                if (bIsValid) {
                                    $.ajax(
                                    {
                                        url      : '/ajax/submitContactForm',
                                        method   : 'POST',
                                        data     : $form.serialize(),
                                        complete : function(oResponse)
                                        {
                                            $form.height($form.height());
                                            TweenMax.to($form, 0.7, { height : 0, onComplete : function()
                                            {
                                                var $msg;
                                                
                                                if (parseInt(oResponse.responseText)) {
                                                    $msg = $form.parent().find('.success-message');
                                                } else {
                                                    $msg = $form.parent().find('.error-message');
                                                }
                                                $form.remove();
                                                $msg.removeClass('hidden');
                                                TweenMax.set($msg, { height : "auto" });
                                                TweenMax.from($msg, 0.5, { height : 0 });
                                            } });
                                        }
                                    });
                                }
                            } else {
                                $('#captcha').addClass('invalid');
                            }
                        }
                    });
                    
                    return false;
                });
            }
        };

        /* Initialize ... */
        self.initialize();     
    };
    
    
    /**
     * Logs the given content,
     * by using global sLog() function.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-07-03
     * 
     * @param {mixed} mContent
     * @param {Boolean} bForce
     * @returns {undefined}
     */
    var log = function(mContent, bForce)
    {
        sLog(mContent, sName, bForce);
    };    
    
    return OfferForm;      
    
});
