require.config(
{
    waitSeconds: 200,

    paths: {
        'jquery'       : 'vendor/jquery-1.x/jquery-1.11.1.min',
        'jquery-ui'    : 'vendor/jquery-ui-1.11.4/jquery-ui.min',
        'jquery-cycle' : 'vendor/jqcycle2/build/jquery.cycle2',
        'jquery-path'  : 'modules/jquery.get-path',
        'jquery-cycle-swipe' : 'vendor/jqcycle2-swipe/jquery.cycle2.swipe.min',
        'fancybox'     : 'vendor/fancybox/source/jquery.fancybox',
        'TweenLite'    : 'vendor/greensock/src/uncompressed/TweenLite',
        'TweenMax'     : 'vendor/greensock/src/uncompressed/TweenMax',
        'TimelineMax'  : 'vendor/greensock/src/minified/TimelineMax.min',
        'application'  : 'application',
        'base-slider'  : 'modules/module.base-slider',
        'wp-addcomment': '../../../../../wp/wp-includes/js/comment-reply',
        'text-shorten' : 'modules/sopg.text-shortener',
        'easing'       : 'vendor/greensock/src/uncompressed/easing/EasePack',
        'price-calc'   : 'modules/module.price-calculator',
        'elementor'    : 'modules/module.content-elementor',
        'match-height' : 'vendor/jquery-matchHeight/jquery.matchHeight',
        'jquery-mmenu' : 'vendor/jquery-mmenu/jquery.mmenu.min.all',
        'form-validator' : 'modules/module.form-validator',        
        'offer-form'   : 'modules/module.offer-form',
        'js-cookie'    : 'vendor/js-cookie/js.cookie',
        'cookie-policy': 'modules/module.cookie-policy'
        //'validator'    : 'validator'
    },
    shim:
    {
        'jquery-ui' : {
            deps : ['jquery']
        },
        'price-calc' : {
            deps : ['jquery']
        },
        'elementor' : {
            deps : ['jquery']
        },
        'jquery-cycle' : {
            deps : ['jquery']
        },  
        'jquery-cycle-swipe' : {
            deps : ['jquery-cycle']
        },
        'jquery-path' : {
            deps : ['jquery']
        },
        'base-slider'  : {
            deps : ['jquery-cycle']
        },
        'text-shorten' : {
            deps : ['jquery']
        },
        'fancybox' : {
            deps : ['jquery']
        },           
        'TweenLite' : {
            deps : ['easing']
        },
        'match-height' : {
            deps : ['jquery']
        },
        'jquery-mmenu' :{
            deps : ['jquery']
        },
        'form-validator':{
            deps : ['jquery']
        },        
        'offer-form':{
            deps : ['jquery','form-validator']
        },
        'cookie-policy':{
            deps : ['jquery','js-cookie']
        }
    }
});

/**
 * THIS IS THE MAIN ENTRY POINT
 * 
 * Updated: 2015-08-11
 */
require(['application'], function(Application)
{
    'use strict';

    var Application = new Application();
  
});

define("main", function(){});

