define(
'elementor',[
    'jquery',
    'TweenMax',
    'jquery-cycle',
    'jquery-cycle-swipe',
    'match-height'
], 
function($, TweenMax)
{
    'use strict';
       
    /**
     * Class name.
     * 
     * @type String
     */
    var sName = 'ContentElementor';       
       
    /**
     * Price Calculator class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2015, SOPG GbR
     * 
     * Created: 2015-08-14
     * 
     * @returns {undefined}
     */
    var ContentElementor = function(oSettings, oSelectors)
    {  
        'use strict';

        /**
         * Own instance.
         * 
         * @type BaseSlider
         */
        var self = this;

        /**
         * Module wide selectors.
         * 
         * @type Object
         */
        var oSel = {
            colElems : {
                services : {
                    self    : '.services-container',
                    service : '.service',
                    opener  : '.opener',
                    text    : '.text'
                }
            },
            dropdown : {
                self        : '.js-dropdown-container',
                controller  : '.js-dd-controller',
                content     : '.js-dd-content'
            },
            testimonial : {
                self      : '.testimonial-element',
                container : '.testimonials-container',
                item      : '.testimonial',
                pager     : '.testimonials-pager',
                next      : '.next',
                prev      : '.prev'
            }
            ,tileElems : {
                self        : '.tile-element',
                container   : '.tiles-container',
                outerRow    : '.outer-row',
                innerRow    : '.inner-row',
                tile        : '.tile',
                tileInner   : '.inner-tile'
            }
            ,ce : {
                slider : {
                    self  : '.slider-container .slider',
                    container : '.slider-container .slider',
                    item  : '.item',
                    nav   : '.navigation',
                    pager : '.pager',
                    prev  : '.nav>.item.prev',
                    next  : '.nav>.item.next'
                }
            }
        };

        /**
         * Module wide settings.
         * 
         * @type Object
         */
        var oSet = {
            colElems : {
                service : {
                    animationSpeed : 0.4,
                    animationEase  : Circ.easeIn
                }
            },
            dropdown : {
                animationSpeed : 0.4,
                animationEase  : Circ.easeIn
            },
            testimonials : {
                pagerTemplate  : '<span class="pager-item"></span>',
                pagerCurrClass : 'active',
                pauseOnHover   : true,
                slidingSpeed   : 2,
                timeout        : 5
            },
            ce : {
                slider : {
                    pagerTemplate : '<span class="item"></span>',
                    dataItemIndex : 'data-slider-index',
                    transition    : {
                        name     : 'custom',
                        speed    : 1.0,
                        ease     : Quad.ease,
                        interval : 5.0
                    },
                    activeSlideClass : 'active',
                    activePagerClass : 'active',
                    allowSwiping     : true,
                    pauseOnHover     : true,
                    startPaused      : false,
                    startDelay       : -2                
                }
            }
        };


        /**
         * Initializes the price calculator.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         */
        this.initialize = function()
        {
            log('Initializing ...');
            
            self.initTwoColumnElements();
            self.initTestimonialElements();
            self.initTileElements();
            self.initSlider();
            self.initDropDown();
            
            log('Initialized.');
        };        
        
        
        /**
         * Initializes the testimonials content elements.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         * 
         * @returns {undefined}
         */
        this.initTestimonialElements = function()
        {
            $(oSel.testimonial.self).each(function()
            {
                var $slider = $(this).find(oSel.testimonial.container);
                
                $(this).find(oSel.testimonial.container).cycle(
                {
                    slides           : '> ' + oSel.testimonial.item,
                    fx               : 'scrollHorz',
                    pager            : $slider.parent().find(oSel.testimonial.pager),
                    pagerTemplate    : oSet.testimonials.pagerTemplate,
                    pagerActiveClass : oSet.testimonials.pagerCurrClass,
                    next             : oSel.testimonial.next,
                    prev             : oSel.testimonial.prev,
                    pauseOnHover     : true,
                    speed            : 1000 * oSet.testimonials.slidingSpeed,
                    timeout          : 1000 * oSet.testimonials.timeout,
                    log              : bGlobalDebug
                });
            });
        };
        
       
        /**
         * Initializes each column element 
         * of the content element "two_columns_element".
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         * 
         * @returns {undefined}
         */
        this.initTwoColumnElements = function()
        {
            /* Initialize services column elements. */
            $(oSel.colElems.services.self).each(function()
            {
                /* Iterate the services and bind 
                a click listener to each of them. */
                $(this).find(oSel.colElems.services.service).each(function()
                {
                    self.bindServiceOpenerClickListener($(this));
                });
            });  
        };
        
        this.initDropDown = function()
        {
            /* Initialize dropdown. */
            $(oSel.dropdown.self).each(function()
            {
                /* Iterate the services and bind 
                a click listener to each of them. */
                $(this).find(oSel.dropdown.controller).each(function()
                {
                    self.bindDropDownClickListener($(this));
                });
            });  
        };
        
        this.initTileElements = function()
        {
            log('initTileElements');
             /* Initialize tile elements. */
 
            if ($('html').hasClass('ie')){
                $(oSel.tileElems.innerRow).each(function()
                {
                    $(oSel.tileElems.tileInner).matchHeight(); 
                });
            }
            else{
                $(oSel.tileElems.outerRow).each(function()
                {
                    $(oSel.tileElems.innerRow).matchHeight();
                });          
            };
            //$(oSel.tileElems.tileInner).css('visibility','visible' );
            $(oSel.tileElems.tileInner).css('border','1px solid #9d9d9d' );
        };
        
        /**
         * Binds a click listener
         * to each DropDown.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         * 
         * @param {jQuery} $service
         * @returns {undefined}
         */
        this.bindDropDownClickListener = function($dropdownController)
        {
            log('initDropDown');
            $dropdownController.bind('click', function()
            {
                var $dropdown = $(this).parent();
                //has class oSel.dropdown.self
                var $content = $(this).next(oSel.dropdown.content);
                //var $plusminus = $(this).find($('plus-minus'));
                /* Check, if to open or to close the text. */
                if (0 === $content.height()) {
                    $dropdown.addClass('open');
                    TweenMax.set($content, { height : 'auto' });
                    TweenMax.from(
                        $content, 
                        oSet.dropdown.animationSpeed, 
                        { 
                            css  : { height : 0 },
                            ease : oSet.dropdown.animationEase
                        }
                    );
                } else {
                    $dropdown.removeClass('open');
                    TweenMax.to(
                        $content, 
                        oSet.dropdown.animationSpeed, 
                        { 
                            css  : { height : 0 },
                            ease : oSet.dropdown.animationEase
                        }
                    );
                }
            });
        };
       
       
        /**
         * Binds a click listener
         * to each service opener.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         * 
         * @param {jQuery} $service
         * @returns {undefined}
         */
        this.bindServiceOpenerClickListener = function($service)
        {
            $service.find(oSel.colElems.services.opener).bind('click', function()
            {
                var $service = $(this).parent();
                var $text = $(this).next(oSel.colElems.services.text);
                var $plusminus = $(this).find($('plus-minus'));
                /* Check, if to open or to close the text. */
                if (0 === $text.height()) {
                    $service.addClass('open');
                    TweenMax.set($text, { height : 'auto' });
                    TweenMax.from(
                        $text, 
                        oSet.colElems.service.animationSpeed, 
                        { 
                            css  : { height : 0 },
                            ease : oSet.colElems.service.animationEase
                        }
                    );
                } else {
                    $service.removeClass('open');
                    TweenMax.to(
                        $text, 
                        oSet.colElems.service.animationSpeed, 
                        { 
                            css  : { height : 0 },
                            ease : oSet.colElems.service.animationEase
                        }
                    );
                }
            });
//            $service.find(oSel.colElems.services.opener).bind('mouseenter', function()
//            {
//                console.log($(this));
//                $plusminus = $(this).find($('.plus-minus'));
//                TweenMax.to(
//                   $plusminus,
//                   oSet.colElems.service.animationSpeed,
//                   {
//                       css : { rotate : 90}
//                   }
//                );
//            });
        };
        
                /**
         * Initializes slideshows on all gallery elements.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-20
         * Updated: 2015-08-21
         * 
         * @returns {undefined}
         */
        this.initSlider = function()
        {
            var $slider = $(oSel.ce.slider.self);
            
            if (0 < $slider.length) {
                log('Initializing ' + $slider.length + ' slider ...');
                $slider.each(function(iIdx)
                {
                    var $slide = $(this);
                    /* Bind a custom transition handler 
                    to the cycle-before event. */
                    $slide.on('cycle-before', self.tweenTheSliderTransition);
                    
//                    $(document).click(function(oEvnt){
//                        alert(oEvnt.target);
//                    });
                    $slide.cycle(
                    {
                        slides           : oSel.ce.slider.item,
                        next             : oSel.ce.slider.nav + ' '+ oSel.ce.slider.next, 
                        prev             : oSel.ce.slider.nav + ' ' + oSel.ce.slider.prev, 
                        pager            : oSel.ce.slider.nav + ' ' + oSel.ce.slider.pager,
                        log              : (bGlobalDebug ? bGlobalDebug : false),
                        pagerTemplate    : oSet.ce.slider.pagerTemplate,
                        fx               : oSet.ce.slider.transition.name,
                        slideActiveClass : oSet.ce.slider.activeSlideClass,
                        pagerActiveClass : oSet.ce.slider.activePagerClass,
                        speed            : oSet.ce.slider.transition.speed * 1000,
                        swipe            : oSet.ce.slider.allowSwiping,
                        swipeFx          : oSet.ce.slider.transition.name,
                        timeout          : oSet.ce.slider.transition.interval * 1000,
                        pauseOnHover     : oSet.ce.slider.pauseOnHover,
                        paused           : oSet.ce.slider.startPaused,
                        autoHeight       : 'calc',
                        delay            : oSet.ce.slider.startDelay * 1000
                    });
                });
                //console.log($slider.parent());
                $slider.parent().fadeTo("slow" , 1);
                log('Slider with ' + $slider.children().length + ' slides initialized.');
            }  
        };
        
        
        
        /**
         * Tweens the jQuery Cycle2 transition, 
         * if it gets used as callback 
         * for the "cycle-before" event.
         * 
         * Created: 2015-07-30
         * Updated: 2015-08-20
         * 
         * @param {Object} oEvent
         * @param {Object} oSlideOptions
         * @param {DOMelement} currentSlideElem
         * @param {DOMelement} nextSlideElem
         * @param {String} forwardFlac
         * @returns {undefined}
         */
        this.tweenTheSliderTransition = function(oEvent, oSlideOptions, currentSlideElem, nextSlideElem, forwardFlac)
        {
//            var $curr = $(currentSlideElem);
//            var $next = $(nextSlideElem);
//            
//            var sNextOrig = forwardFlac ? '100%' : '-100%';
//            var sCurrTo   = forwardFlac ? '-100%' : '100%';
            
            
            var $curr = $(currentSlideElem);
            var $next = $(nextSlideElem);
                        
            var iCurrWidth = $curr.width();
            var iNextWidth = $next.width();
            
            var sNextOrig = forwardFlac ? iNextWidth : -iNextWidth;
            var sCurrTo   = forwardFlac ? -iCurrWidth : iCurrWidth;
            
            TweenMax.set($next, { left: sNextOrig, visibility : 'visible'});
            
            TweenMax.to($curr, oSet.ce.slider.transition.speed, { left : sCurrTo, ease : oSet.ce.slider.transition.ease });
            TweenMax.to($next, oSet.ce.slider.transition.speed, { left : 0, ease : oSet.ce.slider.transition.ease });  
        };
        
        

        /* Initialize ... */
        self.initialize(); 
    
    };
    
    
    /**
     * Logs the given content,
     * by using global sLog() function.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-07-03
     * 
     * @param {mixed} mContent
     * @param {Boolean} bForce
     * @returns {undefined}
     */
    var log = function(mContent, bForce)
    {
        sLog(mContent, sName, bForce);
    };    
    
    return ContentElementor;      
    
});
