/**
 * SOPG jQuery plugin
 * 
 * @copyright (c) 2014, SOPG GbR
 * 
 * @param {jQuery} $
 * @returns {object}
 */

define('form-validator',[
    'jquery'
], function($)
{
    'use strict';
       
    /**
     * Class name.
     * 
     * @type String
     */
    var sName = 'Form-Validator';       
       
    /**
     * Form Validator class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2016, SOPG
     * 
     * Created: 2015-08-12
     * Updated: 2016-05-24
     * 
     * @returns {undefined}
     */
    var FormValidator = function()
    {  
        'use strict';

        /**
         * Own instance.
         * 
         * @type BaseSlider
         */
        var self = this;


        /**
         * Module wide settings.
         * 
         * @type Object
         */
//        var oSet = {
////            minCityFieldChars  : 3,
////            ajaxCityListSrcUrl : '/ajax/cityList',
////            paramSeparator     : '&&'
//        };


//        /**
//         * Initializes the price calculator.
//         * 
//         * @author Michael Bindig <mbi@sopg.de>
//         * 
//         * Created: 2015-08-12
//         * Updated: 2015-08-13
//         */
//        this.initialize = function()
//        {
//            log('Initializing ...');
//            
//            
//            log('Initialized.');
//        };        
        
        this._isEmail = function(value) {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            if (re.test(value)){
                return true;
            }
            return false;
        };

        this._IsNotEmpty = function(value) {
            if (value && value.trim()){
                return true;
            }
            return false;
        };

        this._isDate = function(value) 
        {
            //console.log('Starting to validatedate');
            var status = true;
            
            if (!value || value.length < 10 || value.lengh > 10) {
                return false;
            } 
            
            var aDate = value.replace(/-/g,".").split('.');
            
            if (4 === aDate[0].length) {
                aDate.reverse();
            }
//            console.log(aDate);
            
            if (!$.isArray(aDate) || 3 !== aDate.length) {
                return false;
            }
            
            if (2 !== aDate[0].length || parseInt(aDate[0]) > 31 || parseInt(aDate[0] < 1)) {
                return false;
            }
            if (2 !== aDate[1].length || parseInt(aDate[1]) > 12 || parseInt(aDate[1] < 1)) {
                return false;
            }
            var oDate = new Date();
            if (4 !== aDate[2].length || parseInt(aDate[2]) > oDate.getFullYear() || parseInt(aDate[2]) < 1900) {
                return false;
            }
            return status;
        };
        
        
//        this._isDate = function(date) {
//            return ( (new Date(date) !== "Invalid Date") && (!isNaN(new Date(date)) ) ) ? true : false;
//        };

        this._isChecked = function(input) {
            var status = false;
            if (input.prop('checked')) {
                status = true;
            } else {
                status = false;
            }
            return status;
        };

        this.tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';

        this.tagOrComment = new RegExp(
                '<(?:'
        // Comment body.
                + '!--(?:(?:-*[^->])*--+|-?)'
        // Special "raw text" elements whose content should be elided.
                + '|script\\b' + self.tagBody + '>[\\s\\S]*?</script\\s*'
                + '|style\\b' + self.tagBody + '>[\\s\\S]*?</style\\s*'
        // Regular name
                + '|/?[a-z]'
                + self.tagBody
                + ')>',
        'gi');

        /* Initialize ... */
        //self.initialize(); 
    
    };
    
    
    /**
     * Logs the given content,
     * by using global sLog() function.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-07-03
     * 
     * @param {mixed} mContent
     * @param {Boolean} bForce
     * @returns {undefined}
     */
    var log = function(mContent, bForce)
    {
        sLog(mContent, sName, bForce);
    };    
    
    return FormValidator;      
    
});
