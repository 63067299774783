/**
 * Base Slider class.
 * Initializes a pseudo slideshow on element, 
 * having the given CSS selector.
 * Pseudo means, that nothing happens, 
 * except that the CSS class "active" gets toggled 
 * on each element that has the CSS class "slide".
 * 
 * @author Michael Bindig <mbi@sopg.de>
 * @copyright (c) 2014, SOPG GbR
 * 
 * Added: 2014-10-30
 * Updated: 2014-11-03
 * 
 * @param {String} sSliderSelector
 * @returns {BaseSlider}
 */
var BaseSlider = function(sSliderSelector)
{
    'use strict';
        
    /**
     * Own instance.
     * 
     * @type BaseSlider
     */
    var self = this;
    
    /**
     * Speed between toggling.
     * 
     * @type Integer
     */
    var _iSpeed = 3500;
    
    /**
     * jQuery slider object.
     * 
     * @type jQuery
     */
    this.$slider = null;
    
    
    /**
     * Initializes the slider,
     * by adding the slider jQuery object,
     * which gets constructed from the given CSS selector.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * @param {String} sSliderSelector
     * @returns {undefined}
     */
    var _initialize = function(sSliderSelector)
    {
        self._$slider = jQuery(sSliderSelector);
    };
    
    
    /**
     * Runs the slider 
     * and starts toggling the "active" class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Updated: 2014-11-03
     * 
     * @returns {undefined}
     */
    this.run = function()
    {
        /* Get the array of single slides. */
        var aSlides = self._$slider.find('.slide');
        /* Get the amount of single slides. */
        var iLen = aSlides.length;
        /* Add the "active" class initially
        to the first single slide. */
        jQuery(aSlides[0]).addClass('active');
        /* Check, if there is more than one slide. */
        if (1 < aSlides.length) {
            /* Set the interval for the slides. */
            window.setInterval(function()
            {
                /* Loop the slides and handle
                the "active" class on each slide. */
                aSlides.each(function(idx)
                {
                    /* Do stuff only, if current slide
                    has the "active" class. */
                    if (jQuery(this).hasClass('active')) {
                        /* If it isn't the last slide,
                        set the "active" class on the next one. */
                        if ((idx + 1) < iLen) {
                            jQuery(aSlides[(idx + 1)]).addClass('active');
                        /* If it is the last slide, 
                        set the "active" class on the first one. */
                        } else {
                            jQuery(aSlides[0]).addClass('active');
                        }
                        /* Finally, remove the "active" class 
                        from the current slide. */
                        jQuery(this).removeClass('active');

                        return false;
                    }
                });
            }, _iSpeed);
        }
    };
    
    /* Initialize ... */
    _initialize(sSliderSelector);
    
    return self;
    
};
define("base-slider", ["jquery-cycle"], function(){});

