define('application',[
    'jquery',
    'TweenMax',
    'TimelineMax',
    'price-calc',
    'elementor',
    'offer-form',
    'cookie-policy',
    'jquery-ui',
    'jquery-mmenu',
    'fancybox',
    'jquery-cycle',
    'jquery-path',
    'base-slider',
    'wp-addcomment',
    'text-shorten'
    //'validator'
], function($, TweenMax, TimelineMax, PriceCalculator, ContentElementor, OfferForm, CookiePolicy)
{
    'use strict';
       
    /**
     * Application class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2015, SOPG GbR
     * 
     * Created: 2014-10-30
     * Updated: 2015-08-14
     * 
     * @returns {undefined}
     */
    var Application = function()
    {
        /**
         * Own instance.
         * 
         * @type Application
         */
        var self = this;
        
        
        /**
         * Flac to dertermine, 
         * whether to log or not.
         * 
         * @type Boolean
         */
        var bDebug = false;
        
        /**
         * Global selectors.
         * 
         * @type Object
         */
        var oSel = {
            elements : {
                tiles : {
                    self : '.tile-element',
                    tile : {
                        self : '.tile',
                        link : '.tile-link'
                    }
                }
            }
        };
        
        /**
         * Price calculator instance.
         * 
         * @type PriceCalculator
         */
        this._oPriceCalculator = null;
        this._oOfferForm = null;
        
        
        /**
         * Initializes this class 
         * by setting all necessary data 
         * and calling all necessary methods.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Updated: 2015-08-12
         * 
         * @returns {undefined}
         */
        this._initialize = function()
        {
            self._setAdditionalBrowserCssClasses();  
            self._initContentElementor();
            self._initMobileMenu();

            self._initOfferForm();
            self._initCookiePolicy();
            
            self._bindCeTileClickListener();
            self._initPriceCalculator();
        };
        
        
        /**
         * Instantiates and sets the price calculator.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * 
         * @returns {undefined}
         */
        this._initPriceCalculator = function()
        {
            self._oPriceCalculator = new PriceCalculator();
        };
        
        /**
         * Instantiates and sets the price calculator.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * 
         * @returns {undefined}
         */
        this._initOfferForm = function()
        {
            self._oOfferForm = new OfferForm();
        };
        
         /**
         * Instantiates and sets the price calculator.
         * 
         * @author Kai Schönhoff <ks@sopg.de>
         * 
         * Created: 2015-09-04
         * 
         * @returns {undefined}
         */
        
        this._initMobileMenu = function()
        { 
            $(document).ready(function() {
                $("#mobile-menu").mmenu({
                    //options
                    header:true,
                    slidingSubmenus: false,
                    classes: 'mm-white',
                    selected: 'current-menu-item',
                    "offCanvas": {
                        "position": "right"
                    }

                },{
                   //configuartions
                   panelNodetype: 'ul, li'
                });
                $(".mm-header").click(function() {
                    $("#mobile-menu").trigger("close.mm");
                });
            });
        };
        
        /**
         * Initializes the Content Elementor 
         * module by instantiating it.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         * 
         * @returns {undefined}
         */
        this._initContentElementor = function()
        {
            self._oContentElementor = new ContentElementor();
            
        };
        
        /**
         * Initializes the Cockie Policy
         * module by instantiating it.
         * 
         * @author ks@sopg.de
         * 
         * Created: 2018-05-16
         * 
         * @returns {undefined}
         */
        this._initCookiePolicy = function()
        {
            self._oCookiePolicy = new CookiePolicy(); 
        };        
        
        /**
         * Binds a click listener 
         * to each single tile.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * 
         * @returns {undefined}
         */
        this._bindCeTileClickListener = function()
        {
            var $tiles = $(oSel.elements.tiles.self).find(oSel.elements.tiles.tile.self);
            if (0 < $tiles.length) {
                
                $tiles.find('input').bind('click', function(oEvnt)
                {
                    oEvnt.stopPropagation();
                });
                
                $tiles.find('a').bind('click', function(oEvnt)
                {
                    oEvnt.stopPropagation();
                });
                
                $tiles.on('click', function(Evt)
                {   
                    //console.log(Evt);
                    var $a = $(this).find(oSel.elements.tiles.tile.link).find('a');
                    var sUrl = $a.attr('href');
                    var sTar = $a.attr('target');
                    window.open(sUrl, sTar);
                });
            }
        };
        
        
        /**
         * Binds click handlers to the comment form, 
         * which repair the comment form, after WordPress breaks it.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-12-08
         * 
         * @returns {undefined}
         */
        this._bindCommentFormRepair = function()
        {
            jQuery('#cancel-comment-reply-link').on('click', function()
            {
                window.setTimeout(function()
                {
                    if (0 === jQuery('#wp-temp-form-div').length) {
                        var $h2 = jQuery('#respond').next('h2');
                        jQuery('#respond').parent().prepend($h2);
                        jQuery('#respond').find('h3').removeClass('shown');
                    }
                }, 50);
            });
            jQuery('.comment-reply-link').on('click', function()
            {
                jQuery('#commentArea').addClass('comment-reply');
            });
        };
                
        
        /**
         * Provides placeholder support for IE9-.
         * 
         * @author: Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-11-17
         * 
         * @returns {undefined}
         */
        this._pretendPlaceholderSupportForIe = function()
        {
            var sActivePlaceholderClass = 'active-placeholder';
            
            if ((true === window.app.browser.ie.ie) 
            && (false === window.app.browser.ie.ie10)) {
                jQuery('#commentArea input').each(function(iIdx)
                {
                    var $elem = jQuery(this);
                    if (undefined !== $elem.attr('placeholder')) {
                        var active = document.activeElement;
                        $elem.focus(function()
                        {
                            if ((jQuery(this).attr('placeholder') !== '')
                            && (jQuery(this).val() === jQuery(this).attr('placeholder'))) {
                                jQuery(this).val('').removeClass(sActivePlaceholderClass);
                            }
                        }).blur(function()
                        {
                            if ((jQuery(this).attr('placeholder') !== '')
                            && (((jQuery(this).val() === '') 
                               ||
                               (jQuery(this).val() === jQuery(this).attr('placeholder'))))
                            ) {
                                jQuery(this).val(jQuery(this).attr('placeholder')).addClass(sActivePlaceholderClass);
                            }
                        });
                        $elem.blur();
                        $(active).focus();
                        $elem.parents('form').submit(function()
                        {
                            jQuery(this).find(sActivePlaceholderClass).each(function()
                            {
                                jQuery(this).val(''); 
                            });
                        });
                    }
                });
            }
        };
        
        
        /**
         * Adds additional browser CSS classes to the HTML node.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-11-17
         * 
         * @returns {undefined}
         */
        this._setAdditionalBrowserCssClasses = function()
        {
            if (isIe()) {
                $('html').addClass('ie');
            }
//            if (isIe(11)) {
//                $('html').addClass('ie11');
//            }
//            else if (isIe(10)){
//                $('html').addClass('ie10');
//            };
        };
                        
        
        /**
         * Calls all necessary methods
         * to finalize the comment area.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-12-08
         * 
         * @returns {undefined}
         */
        this._finalizeCommentArea = function()
        {
            self._removeUnnecessaryCommentFormContent();
            self._pretendPlaceholderSupportForIe();            
            self._bindCommentFormRepair();
        };
        
        
        /**
         * Removes unnecessary comment form content and sets CSS classes.
         * 
         * @returns {undefined}
         */
        this._removeUnnecessaryCommentFormContent = function()
        {
            var fnRemover = function()
            {
                var $formArea = jQuery('#commentFormArea');
                if (0 < $formArea.length) {
                    var $flac = $formArea.find('#wp-temp-form-div');
                    if (0 < $flac.length) {
                        jQuery('#reply-title').addClass('shown');
                    } else {
                        jQuery('#reply-title').removeClass('shown');
                    }
                }
            };
            
            fnRemover();
            
            jQuery('.comment-reply-link').click(fnRemover);
        };
        
        
        /**
         * Returns TRUE, if mobile mode,
         * otherwise FALSE.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Added: 2014-11-20
         * Updated: 2014-11-21
         * 
         * @type Boolean
         */
        this._isMobile = function()
        {
            return jQuery('html').hasClass(oSettings.mobileClass);
        };
              
        
        /**
         * Logs the given message, 
         * by sending it to the console, 
         * if bDebug property is set to TRUE.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * @param {mixed} mMessage
         * @returns {undefined}
         */
        var log = function(mMessage)
        {
            if (false !== bDebug) {
                console.log(mMessage);
            }
        };
        
        
        /* Finally, 
        initialize the Application. */
        self._initialize();
    };
    
    return Application;
    
});
