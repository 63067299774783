define(
'cookie-policy',[
    'jquery',
    'TweenMax',
    'js-cookie'
], 
function($, TweenMax, Cookies)
{
    'use strict';
       
    /**
     * Class name.
     * 
     * @type String
     */
    var sName = 'CockiePolicy';       
       
    /**
     * Cockie Policy class.
     * 
     * @author ks@sopg.de
     * @copyright (c) 2018, SOPG GbR
     * 
     * Created: 2018-05-16
     * 
     * @returns {undefined}
     */
    var CookiePolicy = function(oSettings, oSelectors)
    {  
        'use strict';
        
        /**
         * Own instance.
         * 
         * @type CockiePolicy
         */
        var self = this;

        /**
         * Module wide selectors.
         * PRIVATE (only callable from CookiePolicy object.
         * @type Object
         */
        var oSel = {
            cookiePolicy : {
                container : ".js-cookie-policy-container",
                close : ".js-cookie-policy-close",
                accept : ".js-cookie-policy-accept"
            }
        };
        

        /**
         * Module wide settings.
         * 
         * @type Object
         */
        var oSet = {
            cookie : {
                policy : 'aeterna_bestattungen_cookie_policy_accepted',
                expires : 365
            },
            classes : {
                ready : "ready"
            }
        };

        this._oCookiePolicy = null;

        /**
         * Initializes the price calculator.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-14
         */
        this.initialize = function()
        {
            log('Initializing ...');
            
            self._initCookiePolicy();
            
            log('Initialized.');
        };
        
  
        /**
          * Initializes the cookie policy overlay.
          * 
          * @author Michael Bindig <mbi@sopg.de>
          * @jira CHEF-83
          * 
          * Created: 2017-05-22
          * 
          * @author ks@sopg.de
          * 
          * Updated: 2018-05-16
          * 
          * @returns {Application}
          */
        this._initCookiePolicy = function()
        {
            var self = this;
            var $policy = $(oSel.cookiePolicy.container);
            
            if (0 < $policy.length) {
  
                var sPolicyCookieVal = Cookies.get(oSet.cookie.policy); 

                if ('undefined' === typeof sPolicyCookieVal 
                || (!sPolicyCookieVal) 
                || (0 === parseInt(sPolicyCookieVal))) {
                    /* Bind a click listener to the 
                    ACCEPT button, tht sets a cookie. */
                    $policy.find(oSel.cookiePolicy.accept).bind('click', function(oEvent)
                    {
                        oEvent.preventDefault();
                        log('Policy accepted');
                        log('expires: ' + oSet.cookie.expires);
                        
                        /* Set the cookie. */
                        Cookies.set(
                            oSet.cookie.policy, 
                            '1', 
                            { 
                                expires: oSet.cookie.expires, 
                                path: '/' 
                            }
                        );

                        TweenMax.to(
                            $policy, 
                            0.35, 
                            {
                                bottom     : -$policy.outerHeight(), 
                                onComplete : function()
                                {
                                    $policy.remove();
                                } 
                            }
                        );
                        log('name: ' + oSet.cookie.policy);
                        return false;
                    });

                    /* Bind a click listener to the close 
                    button, that closes the overlay. */
                    $policy.find(oSel.cookiePolicy.close).bind('click', function(oEvent)
                    {
                        oEvent.preventDefault();

                        TweenMax.to(
                            $policy, 
                            0.35, 
                            {
                                bottom     : -$policy.outerHeight(), 
                                onComplete : function()
                                {
                                    $policy.remove();
                                } 
                            }
                        );
                        $policy.remove();

                        return false;
                    });

                    TweenMax.set($policy, { bottom : -$policy.outerHeight() });
                    $policy.addClass(oSet.classes.ready);
                    TweenMax.to($policy, 0.35, { bottom : 0 });

                } else {

                    $policy.remove();
                }
            }

            return this;
        };

        
        /* Initialize ... */
        self.initialize(); 
    
    };
        
    /**
     * Logs the given content,
     * by using global sLog() function.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-07-03
     * 
     * @param {mixed} mContent
     * @param {Boolean} bForce
     * @returns {undefined}
     */
    var log = function(mContent, bForce)
    {
        sLog(mContent, sName, bForce);
    };    
    
    return CookiePolicy;      
    
});
