define('price-calc',[
    'jquery'
], function($)
{
    'use strict';
       
    /**
     * Class name.
     * 
     * @type String
     */
    var sName = 'PriceCalculator';       
       
    /**
     * Price Calculator class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2016, SOPG
     * 
     * Created: 2015-08-12
     * Updated: 2016-05-23
     * 
     * @returns {undefined}
     */
    var PriceCalculator = function()
    {  
        'use strict';

        /**
         * Own instance.
         * 
         * @type BaseSlider
         */
        var self = this;

        /**
         * Module wide selectors.
         * 
         * @type Object
         */
        var oSel = {
            priceCalculatorWrapper : '.pricecalculator-wrapper',
            priceCalculator : '.price-calculator',
            form            : '.price-calculator-form',
            input           : '.in-location',
            hiddenPostcode  : '.hidden-postcode',
            hiddenCity      : '.hidden-city',
            hiddenRegion    : '.hidden-region',
            hiddenType      : '.hidden-type',
            burialTypeBtn   : '.burial-type-button',
            vertNavigation  : '#verticalNavigation',
            footer          : '#footer',
        };

        /**
         * Module wide settings.
         * 
         * @type Object
         */
        var oSet = {
            minCityFieldChars  : 3,
            ajaxCityListSrcUrl : '/ajax/cityList',
            paramSeparator     : '&&'
        };


        /**
         * Initializes the price calculator.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * Updated: 2015-08-13
         */
        this.initialize = function()
        {
            log('Initializing ...');
            
            var $priceCalculator = $(oSel.priceCalculator);
            
            if (0 < $priceCalculator.length) {
                $priceCalculator.each(function()
                {
                    self.initAutocomplete($(this));
                    self.bindFormSubmitListener($(this));
                });
            }      
            
            self.bindBurialTypeButtonClickListener();
            
            log('Initialized.');
        };        
        
        
        /**
         * Binds a submit handler 
         * to given calculator's formular,
         * that checks the form's hidden postcode field.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * Updated: 2015-08-13
         * 
         * @param {jQuery} $calculator
         * @returns {undefined}
         */
        this.bindFormSubmitListener = function($calculator)
        {
            var $form = $calculator.find(oSel.form);
            
            if (0 < $form.length) {
                $form.on('submit', function()
                {
                    if ((/^[0-9]{1,5}$/.test($form.find(oSel.hiddenPostcode).val()))
                    && ('' !== $form.find(oSel.input).val())) {
                        return true;
                    }
                    
                    return false;
                });
                log('Submit handler to price calculator\'s formular bound.');
            }
        };
        
        
        /**
         * Binds a click listener to each burial type button.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-13
         * 
         * @returns {undefined}
         */
        this.bindBurialTypeButtonClickListener = function()
        {
            var $buttons = $(oSel.burialTypeBtn);
            
            if (0 < $buttons.length) {
                $buttons.each(function()
                {
                    $(this).bind('click', function()
                    {
                        var $self = $(this);
                        var $wrapper = $self.closest(oSel.priceCalculatorWrapper);
                        var sBurialType = $self.attr('data-burial-type');
                        var $priceCalculator;
                        log("Burialtype: " . sBurialType);
                        if ( 0 < $wrapper.length){
                            $wrapper.addClass("show");
                            $priceCalculator = $wrapper.find(oSel.priceCalculator);
                        } else {
                             //var $priceCalculatorMobile = $(oSel.footer).find(oSel.priceCalculator);
                            
//                            if($priceCalculatorMobile && $priceCalculatorMobile.is(':visible')){
//                                var $priceCalculator = $priceCalculatorMobile;
//                            } else{
//                                var $priceCalculator = $(oSel.vertNavigation).find(oSel.priceCalculator);
//                            }
                        }
                        //if (undefined !== sBurialType) {
                            if (0 < $priceCalculator.length) {
                                $priceCalculator.find(oSel.hiddenType).val(sBurialType);
                                $priceCalculator.find(oSel.input).focus();
                                return;
                            }
                        //}
                        window.location.href = window.cortex.url.offersPage;
                    });
                });
            }
        };
        

        /**
         * Initializes the auto completion 
         * of the given price calculator's city field.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-08-12
         * Updated: 2016-05-23
         * 
         * @param {jQuery} $calculator
         * @returns {undefined}
         */
        this.initAutocomplete = function($calculator)
        {
            log('Initializing automatic completion of price calculator\'s city field.');
            var $input = $calculator.find(oSel.input);
            
            if (0 < $input.length) {
                $input.autocomplete({
                    source    : oSet.ajaxCityListSrcUrl,
                    minLength : oSet.minCityFieldChars,
                    /* When starting to search ... */
                    search    : function(oEvnt, oUi) 
                    {
                        $input.addClass('searching');
                    },
                    /* When result list opens ... */
                    open  : function(oEvnt, oUi)
                    {
                        $input.removeClass('searching').removeClass('no-result');
                    },
                    /* When server responds ... */
                    response : function(oEvnt, oUi)
                    {
                        if (0 === oUi.content.length) {
                            $input.removeClass('searching').addClass('no-result');
                        }
                    },
                    /* When selecting a result list item ... */
                    select    : function(oEvnt, oUi)
                    {
                        oEvnt.preventDefault();
                        
                        var sVal = oUi.item.value;
                        var aVal = sVal.split(oSet.paramSeparator);
                        
                        var sPostcode = aVal[0],
                            sCity     = aVal[1],
                            sRegion   = aVal[2];
                        
                        $calculator.find(oSel.hiddenPostcode).val(
                            /* Added 2016-05-23, AET-65: */
                            sPostcode.replace(String.fromCharCode(65279), "")
                        );
                        $calculator.find(oSel.hiddenCity).val(sCity);
                        $calculator.find(oSel.hiddenRegion).val(sRegion);
                                                
                        $input.val(oUi.item.label);
                        
                        $calculator.find(oSel.form).submit();
                    }
                });
            }  
            log('Automatic completion of price calculator\'s city field initialized.');
        };


        /* Initialize ... */
        self.initialize(); 
    
    };
    
    
    /**
     * Logs the given content,
     * by using global sLog() function.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-07-03
     * 
     * @param {mixed} mContent
     * @param {Boolean} bForce
     * @returns {undefined}
     */
    var log = function(mContent, bForce)
    {
        sLog(mContent, sName, bForce);
    };    
    
    return PriceCalculator;      
    
});
